export * from './hooks';

// https://www.w3schools.com/js/js_cookies.asp
export function getCookie(cookieName: string): string | null {
  const name = `${cookieName}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function slugify(text: string): string {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-'); // Replace multiple - with single -
}

export function addParamsToLink(link: string, params: string): string {
  return `${link.indexOf('http') === 0 ? '' : 'https://'}${link}${
    link.indexOf('?') > -1 ? '&' : '?'
  }${params}`;
}

export const SAVED_DETAILS_KEY = 'savedDetails';

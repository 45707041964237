import SEO from '@/components/SEO';
import SocialIcon from '@/components/SocialIcon';
import ProductCard from '@/components/product/ProductCard';
import ContactModal from '@/components/supplier/ContactDetailsModal';
import RequestQuoteModal from '@/components/supplier/RequestQuoteModal';
import { MissingImageIcon } from '@/images/product';
import { addParamsToLink, useIsMobile } from '@/lib';
import { useModal } from '@/lib/modal';
import { ClipboardListIcon, PhoneIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import Layout from '../components/Layout';

const SupplierPage: FC<PageProps<any>> = ({ data: { supplier } }) => {
  const {
    path,
    name,
    image,
    description,
    areaServed,
    fbLink,
    linLink,
    instaLink,
    tikTokLink,
    website,
    projects,
    tags,
    showOffProducts,
    products,
  } = supplier;

  const isMobile = useIsMobile();
  const contactModal = useModal();
  const requestQuoteModal = useModal();

  const noOfProducts = products.length;
  return (
    <Layout
      fsoHeader
      minimalFooter
      mainClassName="relative min-h-screen bg-gray-200"
    >
      <SEO
        title={name}
        description={description}
        url={path}
        image={
          image?.childImageSharp
            ? {
                src: image.childImageSharp.gatsbyImageData.images.fallback.src,
                width: image.childImageSharp.gatsbyImageData.width,
                height: image.childImageSharp.gatsbyImageData.height,
              }
            : undefined
        }
        lang="ro"
      />
      <div
        className="w-full max-w-5xl m-auto px-4 pt-6 grid grid-cols-1 lg:grid-cols-3 gap-x-10 gap-y-4 pb-10"
        style={{ gridTemplateRows: 'repeat(2, auto) 1fr' }}
      >
        <section className="lg:col-start-1 lg:row-span-6">
          {/* image carousel */}
          <div className="flex flex-col items-center justify-start h-full">
            {image?.childImageSharp ? (
              <GatsbyImage
                className="w-full max-h-full mb-4"
                alt={name}
                image={getImage(image)}
                objectFit="contain"
              />
            ) : (
              <MissingImageIcon className="w-full max-h-full text-gray-250" />
            )}

            {supplier.category && (
              <div className="w-full bg-yellow text-center mt-4 px-4 py-2">
                {supplier.category}
              </div>
            )}
          </div>
        </section>

        <section className="lg:col-start-2 ">
          <div className="font-semibold lg:font-bold text-center lg:text-left text-base text-3xl">
            {name}
          </div>
        </section>

        <section className="lg:col-start-2 -mt-4">
          <div className="lg:text-base text-center lg:text-left text-gray-500 opacity-60">
            {areaServed ?? 'Romania'}
          </div>
        </section>

        <section className="lg:col-start-2">
          <div className="flex items center justify-center lg:justify-start gap-4 text-primary-200">
            {fbLink && (
              <a target="_blank" rel="noopener noreferrer" href={fbLink}>
                <SocialIcon platform="facebook" />
              </a>
            )}
            {linLink && (
              <a target="_blank" rel="noopener noreferrer" href={linLink}>
                <SocialIcon platform="linkedin" />
              </a>
            )}
            {instaLink && (
              <a target="_blank" rel="noopener noreferrer" href={instaLink}>
                <SocialIcon platform="instagram" />
              </a>
            )}
            {tikTokLink && (
              <a target="_blank" rel="noopener noreferrer" href={tikTokLink}>
                <SocialIcon platform="tiktok" />
              </a>
            )}
            {website && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={addParamsToLink(website, 'utm_content=profile_page')}
              >
                <SocialIcon platform="website" />
              </a>
            )}
          </div>
        </section>

        <section className="lg:col-start-2 lg:col-span-2">
          <div className="text-base mt-4 flex flex-wrap gap-2">
            {tags.map((tag) => (
              <div
                key={tag}
                className="bg-primary-100 px-2 py-1 text-white text-xs rounded-full text-sm"
              >
                {tag}
              </div>
            ))}
          </div>
        </section>

        {description && (
          <section className="lg:col-start-2 lg:col-span-2 border-t border-gray-250">
            {/* description */}
            <div className="mt-2 text-gray-500">
              {description
                .split('\n')
                .map((it, idx) => ({ it, idx }))
                .map(({ it, idx }) => (
                  <p key={idx} className="mb-2">
                    {it}
                  </p>
                ))}
            </div>
          </section>
        )}

        {!!projects.length && (
          <section className="lg:col-span-3 border-t border-gray-250">
            <h2 className="mt-2 mb-4 font-semibold text-base">Proiecte</h2>
            <div
              className="grid justify-between gap-4 lg:gap-10 max-w-full"
              style={{
                gridTemplateColumns: isMobile
                  ? 'repeat(auto-fill, minmax(15rem, 1fr))'
                  : '1fr 1fr',
              }}
            >
              {projects
                .map((project, idx) => ({ project, idx }))
                .map(({ project, idx }) => (
                  <a
                    key={idx}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.link}
                    className={clsx('flex gap-4 flex-col bg-white p-3 h-full')}
                  >
                    {project.image ? (
                      <GatsbyImage
                        className="-mx-3 -mt-3"
                        alt={`${name} - ${project.name}`}
                        image={getImage(project.image)}
                        objectFit="contain"
                      />
                    ) : (
                      <MissingImageIcon className="w-32 h-32 lg:w-44 lg:h-44 text-gray-250 self-center" />
                    )}
                    <div className="flex-1 flex flex-col min-w-0">
                      <div className="text-gray-500 flex justify-between">
                        <span className="font-semibold">{project.name}</span>
                        <span className="opacity-60">{project.date}</span>
                      </div>
                      {project.projectDescription && (
                        <div className="text-xs mt-2 opacity-50">
                          {project.projectDescription}
                        </div>
                      )}
                      {project.contributionDescription && (
                        <div className="text-xs mt-2 opacity-50">
                          {project.contributionDescription}
                        </div>
                      )}
                      {project.servicesProvided && (
                        <div className="text-base mt-4 flex flex-wrap gap-2">
                          {project.servicesProvided.map((service) => (
                            <div
                              key={service}
                              className="bg-primary-100 px-2 py-1 text-white text-xs rounded-full text-sm"
                            >
                              {service}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </a>
                ))}
            </div>
          </section>
        )}

        {!!noOfProducts && (
          <section className="lg:col-span-3 border-t border-gray-250">
            <h2 className="mt-2 font-semibold text-base">Catalog</h2>
            <div className="opacity-60 mb-4 text-xs">
              {noOfProducts} produse
            </div>
            <div
              className="grid justify-between gap-2 lg:gap-10 max-w-full"
              style={{
                gridTemplateColumns: isMobile
                  ? 'repeat(auto-fill, minmax(9.5rem, 1fr))'
                  : 'repeat(auto-fill, 11.5rem)',
              }}
            >
              {showOffProducts
                .map((prod, idx) => ({ prod, idx }))
                .map(({ prod, idx }) => (
                  <ProductCard key={idx} prod={prod} />
                ))}
            </div>
            <Link
              to="/ro/cauta-produse/"
              className="mt-4 lg:mb-8 w-full lg:w-auto flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-semibold bg-primary text-white"
            >
              Vezi toate produsele
            </Link>
          </section>
        )}

        <section className="sticky bottom-0 lg:bottom-auto lg:top-0 mt-6 lg:mb-4 lg:mt-2 lg:col-start-3 lg:row-start-1 lg:row-span-3">
          {/* button */}
          <div className="py-3 lg:pb-4 border-t border-gray-250 lg:border-t-0 -mx-4 px-4 bg-white grid grid-cols-2 lg:grid-cols-1 gap-4 items-center">
            <button
              id="requestSupplierContactInfo"
              type="button"
              className="flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base ring ring-yellow"
              onClick={() => contactModal.open({ supplier })}
            >
              <PhoneIcon className="w-5 h-5 mr-2 lg:mr-4" />
              Contact
            </button>

            <button
              type="button"
              className="flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-semibold bg-yellow hover:bg-yellow"
              onClick={() => requestQuoteModal.open({ supplier })}
            >
              <ClipboardListIcon className="w-5 h-5 mr-2 lg:mr-4" />
              Cere oferta
            </button>
          </div>
        </section>
      </div>
      <ContactModal control={contactModal} />
      <RequestQuoteModal control={requestQuoteModal} />
    </Layout>
  );
};

export const query = graphql`
  query SupplierPageQuery($id: String!) {
    supplier(id: { eq: $id }) {
      path
      name
      image {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      description
      areaServed
      fbLink
      linLink
      instaLink
      tikTokLink
      website
      projects {
        name
        date
        projectDescription
        link
        location

        contributionDescription
        servicesProvided

        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      tags
      category
      contactPerson
      phone
      email
      address

      products {
        id
      }
      showOffProducts: randomPresentableProducts(limit: 10) {
        path
        name
        code
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        averageMinPrice {
          value
          currency
        }
        averageMaxPrice {
          value
          currency
        }
      }
    }
  }
`;

export default SupplierPage;

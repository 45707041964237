import Modal from '@/components/Modal';
import { ModalControl } from '@/lib/modal';
import {
  AtSymbolIcon,
  LocationMarkerIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { FC } from 'react';

import { Supplier } from '../../../graphql-types';

export type ContactModalData = { supplier: Supplier };

type ContactModalProps = {
  control: ModalControl<ContactModalData>;
};

const ContactModal: FC<ContactModalProps> = ({ control }) => {
  return (
    <>
      <Modal
        control={control}
        title={`Contact ${control.data?.supplier.name}`}
        renderActions={(props) => (
          <>
            <button
              {...props}
              className={clsx(props.className, 'bg-yellow font-semibold')}
              type="button"
              onClick={control.close}
            >
              Ok
            </button>
          </>
        )}
      >
        {control.data && (
          <div className={clsx('flex flex-col gap-4 my-6 py-2')}>
            {control.data.supplier.address && (
              <div className="flex">
                <LocationMarkerIcon className="h-5 w-5 mr-4" />
                {control.data.supplier.address}
              </div>
            )}
            {control.data.supplier.contactPerson && (
              <div className="flex">
                <UserIcon className="h-5 w-5 mr-4" />
                {control.data.supplier.contactPerson}
              </div>
            )}
            {control.data.supplier.phone && (
              <a
                href={`tel:${control.data.supplier.phone}`}
                target="_blank"
                rel="noreferrer"
                className="flex text-primary-300"
              >
                <PhoneIcon className="h-5 w-5 mr-4" />
                {control.data.supplier.phone}
              </a>
            )}
            {control.data.supplier.email && (
              <a
                href={`mail:${control.data.supplier.email}`}
                target="_blank"
                rel="noreferrer"
                className="flex text-primary-300"
              >
                <AtSymbolIcon className="h-5 w-5 mr-4" />
                {control.data.supplier.email}
              </a>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ContactModal;

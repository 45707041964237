import { MissingImageIcon } from '@/images/product';
import { formatPrice } from '@/lib/currency';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC } from 'react';

import { Product } from '../../../graphql-types';

type ProductCardProps = {
  className?: string;
  prod: Product;
};

const ProductCard: FC<ProductCardProps> = ({ className, prod }) => {
  return (
    <Link
      to={prod.path}
      className={clsx(
        className,
        'flex gap-4 lg:w-50 flex-col bg-white p-3 h-full'
      )}
    >
      {prod.image ? (
        <GatsbyImage
          className="w-32 h-32 lg:w-44 lg:h-44 self-center"
          alt={prod.name}
          image={getImage(prod.image as any)}
          objectFit="contain"
        />
      ) : (
        <MissingImageIcon className="w-32 h-32 lg:w-44 lg:h-44 text-gray-250 self-center" />
      )}
      <div className="flex-1 flex flex-col min-w-0">
        <div className="text-gray-500 font-semibold flex-1">{prod.name}</div>
        <div className="text-xs opacity-50 truncate lg:flex-1 flex items-end">
          {prod.code}
        </div>
        <div className="text-base mt-2">
          <div className="font-semibold text-red">
            {formatPrice(
              prod.averageMinPrice.value,
              prod.averageMinPrice.currency,
              false,
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )}
            {' - '}
            {formatPrice(
              prod.averageMaxPrice.value,
              prod.averageMaxPrice.currency,
              false,
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;

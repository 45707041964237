import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
} from '@/images/footer';
import { GlobeAltIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type SocialIconProps = {
  platform: string;
  className?: string;
};

const SocialIcon: FC<SocialIconProps> = ({ platform, className }) => {
  let Tag;
  switch (platform) {
    case 'linkedin':
      Tag = LinkedinIcon;
      break;
    case 'instagram':
      Tag = InstagramIcon;
      break;
    case 'facebook':
      Tag = FacebookIcon;
      break;
    case 'website':
      Tag = GlobeAltIcon;
      break;
    case 'tiktok':
      Tag = TikTokIcon;
      break;
    default:
      Tag = null;
  }
  if (!Tag) return null;
  return <Tag className={clsx('h-7 w-7', className)} />;
};

export default SocialIcon;

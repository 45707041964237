// TODO: move precision to currency, this way we can support bitcoin and what not
// copied from "@optioffer/core"

export const formatPrice = (
  price: number,
  currency: string,
  hideCurrencySymbol = false,
  options: Intl.NumberFormatOptions = {}
): string =>
  price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: hideCurrencySymbol ? undefined : 'currency',
    currency: hideCurrencySymbol ? undefined : currency,
    ...options,
  });
